import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Home from './Home';
import { app } from './firebase';
import { WaitlistProvider } from './WaitlistContext';

// Define page metadata for each route
const PAGE_METADATA = {
  '/': {
    title: 'Huffman - Visual Development Platform',
    description: 'Transform your development workflow with our innovative block-based approach.',
    path: 'home'
  },
  '/lander': {
    title: 'Huffman - Get Started',
    description: 'Start your visual programming journey with Huffman.',
    path: 'lander'
  }
};

// Analytics Observer Component
const AnalyticsObserver: React.FC = () => {
  const location = useLocation();
  const analytics = getAnalytics(app);
  const currentPage = PAGE_METADATA[location.pathname as keyof typeof PAGE_METADATA] || PAGE_METADATA['/'];

  useEffect(() => {
    // Update document title
    document.title = currentPage.title;

    const trackPageView = () => {
      // Enhanced page view tracking
      logEvent(analytics, 'page_view', {
        page_title: currentPage.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
        page_section: currentPage.path
      });

      // Track user engagement with more details
      logEvent(analytics, 'user_engagement', {
        engagement_time: performance.now(),
        page_path: location.pathname,
        page_section: currentPage.path,
        referrer: document.referrer,
        viewport_width: window.innerWidth,
        viewport_height: window.innerHeight,
        user_language: navigator.language
      });

      // Enhanced navigation tracking
      logEvent(analytics, 'navigation', {
        type: performance.navigation?.type,
        path: location.pathname,
        section: currentPage.path,
        timestamp: new Date().toISOString()
      });
    };

    // Enhanced performance tracking
    const trackPerformance = () => {
      if (window.performance && window.performance.timing) {
        const timing = window.performance.timing;
        const paint = performance.getEntriesByType('paint');
        const firstPaint = paint.find(entry => entry.name === 'first-paint')?.startTime;
        const firstContentfulPaint = paint.find(
          entry => entry.name === 'first-contentful-paint'
        )?.startTime;

        logEvent(analytics, 'performance_metrics', {
          page_load_time: timing.loadEventEnd - timing.navigationStart,
          dom_ready_time: timing.domContentLoadedEventEnd - timing.navigationStart,
          first_paint: firstPaint,
          first_contentful_paint: firstContentfulPaint,
          page_path: location.pathname,
          section: currentPage.path,
          connection_type: (navigator as any).connection?.effectiveType || 'unknown',
          device_memory: (navigator as any).deviceMemory || 'unknown'
        });
      }
    };

    // Track initial page view
    trackPageView();

    // Set up performance tracking
    window.addEventListener('load', trackPerformance);

    // Track scroll depth
    const trackScrollDepth = () => {
      const winHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;

      if (scrollPercent >= 25 && scrollPercent < 50) {
        logEvent(analytics, 'scroll_depth', { depth: '25%', page: currentPage.path });
      } else if (scrollPercent >= 50 && scrollPercent < 75) {
        logEvent(analytics, 'scroll_depth', { depth: '50%', page: currentPage.path });
      } else if (scrollPercent >= 75 && scrollPercent < 90) {
        logEvent(analytics, 'scroll_depth', { depth: '75%', page: currentPage.path });
      } else if (scrollPercent >= 90) {
        logEvent(analytics, 'scroll_depth', { depth: '90%', page: currentPage.path });
      }
    };

    window.addEventListener('scroll', trackScrollDepth);

    return () => {
      window.removeEventListener('load', trackPerformance);
      window.removeEventListener('scroll', trackScrollDepth);
    };
  }, [location, analytics, currentPage]);

  // Track when user is about to leave with enhanced data
  useEffect(() => {
    const handleBeforeUnload = () => {
      logEvent(analytics, 'user_exit', {
        exit_page: location.pathname,
        section: currentPage.path,
        time_spent: performance.now(),
        scroll_depth: (window.scrollY / document.documentElement.scrollHeight) * 100,
        exit_time: new Date().toISOString(),
        had_interaction: Boolean(performance.getEntriesByType('event').length)
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [location, analytics, currentPage]);

  return (
    <Helmet>
      <title>{currentPage.title}</title>
      <meta name="description" content={currentPage.description} />
      <meta property="og:title" content={currentPage.title} />
      <meta property="og:description" content={currentPage.description} />
      <meta name="twitter:title" content={currentPage.title} />
      <meta name="twitter:description" content={currentPage.description} />
    </Helmet>
  );
};

function App() {
  return (
    <HelmetProvider>
   
      <Router>
        <AnalyticsObserver />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lander" element={<Home />} />
        </Routes>
      </Router>
         
    </HelmetProvider>
  );
}

export default App;