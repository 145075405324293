import React, { useState } from 'react';
import { Alert } from './Alert';
import { saveToWaitlist } from './firebase';
import { Sparkles } from 'lucide-react';

interface WaitlistFormProps {
    onClose: () => void;
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setStatus('loading');

        try {
            await saveToWaitlist(email);
            setStatus('success');
            setMessage('Thanks for joining our waitlist! We\'ll keep you updated.');
            setEmail('');
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            setStatus('error');
            setMessage(error instanceof Error ? error.message : 'Something went wrong. Please try again.');
        }
    };

    return (
        <div className="relative max-w-md mx-auto">
            {/* Animated gradient border */}
            <div className="absolute -inset-1 bg-gradient-to-r from-pink-500 via-indigo-500 to-purple-500 
                rounded-lg blur opacity-75 animate-pulse group-hover:opacity-100 transition duration-1000"></div>

            {/* Main content */}
            <div className="relative p-8 bg-gray-800/90 backdrop-blur-xl rounded-lg border border-indigo-500/50 
                shadow-2xl shadow-indigo-500/20">

                {/* Floating sparkles */}
                <div className="absolute -top-3 -right-3">
                    <Sparkles className="w-6 h-6 text-indigo-400 animate-pulse" />
                </div>
                <div className="absolute -bottom-3 -left-3">
                    <Sparkles className="w-6 h-6 text-pink-400 animate-pulse" />
                </div>

                {/* Header */}
                <div className="flex justify-between items-start mb-6">
                    <div className="space-y-1">
                        <h3 className="text-2xl font-bold bg-gradient-to-r from-pink-500 via-indigo-400 to-purple-500 
                            text-transparent bg-clip-text">
                            Join the Waitlist
                        </h3>
                        <p className="text-indigo-300 font-medium">
                            Be among the first to experience the future
                        </p>
                    </div>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white transition-colors"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>

                {/* Early access benefits */}
                <div className="bg-indigo-500/10 rounded-lg p-4 mb-6 border border-indigo-500/20">
                    <ul className="space-y-2 text-sm text-indigo-300">
                        <li className="flex items-center gap-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-indigo-500"></div>
                            Early access to new features
                        </li>
                        <li className="flex items-center gap-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-pink-500"></div>
                            Exclusive beta testing opportunities
                        </li>
                        <li className="flex items-center gap-2">
                            <div className="w-1.5 h-1.5 rounded-full bg-purple-500"></div>
                            Special launch-day benefits
                        </li>
                    </ul>
                </div>

                {/* Alerts */}
                {status === 'success' && (
                    <Alert
                        variant="success"
                        title="Welcome Aboard! 🎉"
                        description={message}
                        className="mb-4"
                    />
                )}

                {status === 'error' && (
                    <Alert
                        variant="error"
                        title="Oops!"
                        description={message}
                        className="mb-4"
                    />
                )}

                {/* Form */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-indigo-300 mb-2">
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-3 bg-gray-900/50 border border-indigo-500/30 rounded-lg 
                                text-white placeholder-gray-400 focus:border-indigo-500 focus:ring-2 
                                focus:ring-indigo-500/50 transition-all"
                            placeholder="you@example.com"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={status === 'loading'}
                        className="w-full px-6 py-4 bg-gradient-to-r from-pink-500 via-indigo-500 to-purple-500 
                            text-white rounded-lg font-medium hover:from-pink-600 hover:via-indigo-600 
                            hover:to-purple-600 transform hover:-translate-y-0.5 transition-all duration-200
                            disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none
                            flex items-center justify-center gap-2 shadow-lg shadow-indigo-500/30"
                    >
                        {status === 'loading' ? (
                            <>
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </>
                        ) : (
                            <>
                                <Sparkles className="w-5 h-5" />
                                Join Exclusive Waitlist
                            </>
                        )}
                    </button>
                </form>

                {/* Additional info */}
                <p className="mt-4 text-center text-sm text-gray-400">
                    Join {Math.floor(Math.random() * 500 + 1500)} others waiting to experience the future of development
                </p>
            </div>
        </div>
    );
};

export default WaitlistForm;