// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
 apiKey: "AIzaSyBomAWi28roYlx6vbhWdjGPbv2KWjMuAGo",
  authDomain: "huffman-d41c0.firebaseapp.com",
  projectId: "huffman-d41c0",
  storageBucket: "huffman-d41c0.firebasestorage.app",
  messagingSenderId: "179763163719",
  appId: "1:179763163719:web:97c0c3ea857c3723e0f762",
  measurementId: "G-C3T0P8ER8T"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
const db = getFirestore(app);

export const saveToWaitlist = async (email: string): Promise<{ success: boolean; id: string }> => {
    try {
        // Create the waitlist collection reference using the db instance
        const waitlistRef = collection(db, 'waitlist');
        
        // Add the document to the collection
        const docRef = await addDoc(waitlistRef, {
            email,
            createdAt: new Date().toISOString(),
            status: 'active'
        });

        console.log('Document written with ID:', docRef.id);
        return { success: true, id: docRef.id };
    } catch (error) {
        console.error('Error adding document:', error);
        throw error;
    }
};

// Export the db instance
export { db };

