import React from 'react';
import { FaApple, FaWindows, FaLinux } from 'react-icons/fa';
import { Clock } from 'lucide-react';
import type { IconType } from 'react-icons';

interface DownloadOption {
    icon: IconType;
    name: string;
    version: string;
    size: string;
    requirements: string;
    url: string;
    color: string;
}

interface Downloads {
    [key: string]: DownloadOption;
}

const EnhancedDownloadSection = () => {
    const downloads: Downloads = {
        mac: {
            icon: FaApple,
            name: 'macOS',
            version: '1.0.0',
            size: '45 MB',
            requirements: 'macOS 10.15 or later',
            url: '#',
            color: 'from-blue-500 to-blue-600'
        },
        windows: {
            icon: FaWindows,
            name: 'Windows',
            version: '1.0.0',
            size: '42 MB',
            requirements: 'Windows 10 or later',
            url: '#',
            color: 'from-indigo-500 to-indigo-600'
        },
        linux: {
            icon: FaLinux,
            name: 'Linux',
            version: '1.0.0',
            size: '40 MB',
            requirements: 'Ubuntu 20.04 or equivalent',
            url: '#',
            color: 'from-purple-500 to-purple-600'
        }
    };

    return (
        <div className="max-w-lg mx-auto transform transition-all duration-300 relative">
            {/* Coming Soon Overlay */}
            <div className="absolute inset-0 bg-gray-900/90 backdrop-blur-sm z-10 rounded-lg 
                flex flex-col items-center justify-center text-center p-6">
                <div className="w-16 h-16 bg-indigo-500/20 rounded-full flex items-center justify-center mb-4">
                    <Clock className="w-8 h-8 text-indigo-400" />
                </div>
                <h3 className="text-xl font-bold text-white mb-2">Coming Soon</h3>
                <p className="text-gray-400 max-w-sm">
                    We're working hard to bring you the best development experience.
                    Downloads will be available soon!
                </p>
            </div>

            {/* Inactive Interface */}
            <div className="opacity-50 pointer-events-none">
                <div className="flex justify-center mb-6 bg-gray-800/30 rounded-full p-1">
                    {Object.entries(downloads).map(([key, value]) => (
                        <div
                            key={key}
                            className="relative px-8 py-3 text-sm font-medium text-gray-400"
                        >
                            <div className="relative flex items-center justify-center gap-2">
                                <value.icon className="w-5 h-5" />
                                <span className="hidden sm:block">{value.name}</span>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-br from-gray-500 to-gray-600 
                        opacity-10 blur-xl" />

                    <div className="relative bg-gray-800/50 backdrop-blur-xl rounded-lg p-6 text-left
                        border border-gray-700/50">
                        <div className="flex items-start justify-between mb-4">
                            <div>
                                <h3 className="text-white font-medium mb-1">
                                    Download Options
                                </h3>
                                <p className="text-gray-400 text-sm">
                                    Choose your platform
                                </p>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="bg-gray-900/50 rounded-lg p-3">
                                <p className="text-gray-400 text-xs">
                                    System Requirements:
                                    <span className="block mt-1 text-gray-500">
                                        Details will be available soon
                                    </span>
                                </p>
                            </div>

                            <button
                                disabled
                                className="w-full px-4 py-3 bg-gray-700 
                                    text-gray-400 rounded-lg font-medium
                                    flex items-center justify-center gap-2"
                            >
                                Download Coming Soon
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnhancedDownloadSection;